<template>
<div class="loginWrapper">
    <div class="logIn">
        <div class="loginInner">
            <a-row>
                <a-col :sm="24" :xs="24">
                    <div class="rightWrapper">
                        <div class="logo">
                            <!-- <img src="@/assets/images/logo.svg" alt="image" /> -->
                            <img src="@/assets/images/logo/logo-2.png" alt="image" />
                        </div>
                        <h1 style="text-align:center"><u>Escalation Action Form</u></h1>
                        <a-form ref="formRef" :model="escalationAction" layout="" autocomplete="off" :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }" @finish="submitForm">
                            <a-col :sm="24" :xs="24">
                                <a-col :span="24">
                                    <div class="formHeading">
                                        <h3>Patient Details</h3>
                                        <hr />
                                    </div>
                                </a-col>
                                <div class="form-group">
                                    <b>Name : </b> <span>{{'Louis Cris'}}</span>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <div class="form-group">
                                    <b>DoB : </b> <span>{{'July 15,1990'}}</span>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <div class="form-group">
                                    <b>Physcian Name : </b> <span>{{'Zampa, Adam'}}</span>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <div class="form-group">
                                    <b>Practice(Building) : </b> <span>{{'A-Block 2nd floor'}}</span>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <div class="form-group">
                                    <b>Date : </b> <span>{{'Aug 01,2022'}}</span>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <div class="form-group">
                                    <b>Conditions : </b> <span>{{'Critical'}}</span>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <div class="form-group">
                                    <b>Priority : </b> <span>{{'High'}}</span>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <div class="form-group">
                                    <b>Escalation Type : </b> <span>{{'Trending'}}</span>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <strong>Escalation Details : </strong>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <a-collapse v-model:activeKey="activeKey" accordion>
                                    <a-collapse-panel key="3" header="Notes">
                                        <a-table rowKey="id" :columns="notesColumns" :data-source="escalation.escalationNots" :pagination="false">
                                            <template #addedBy="{ record }">
                                                <a @click="showStaffModal(record.addedById)">{{ record.addedBy }}</a>
                                            </template>
                                            <template #color="{ record }">
                                                <span>{{ record.flag }}</span>
                                            </template>
                                        </a-table>
                                    </a-collapse-panel>
                                    <a-collapse-panel key="4" header="Vitals">
                                        <PatientVitalsGrid :isEscalationAction="true"/>
                                        <!-- <a-table rowKey="id" :columns="vitalColumns" :data-source="escalationVitals" :pagination="false">
                                            <template #color="{ record }">
                                                <a-tooltip placement="bottom">
                                                    <template #title>
                                                        <span>{{ record.flagName }}</span>
                                                    </template>
                                                    <a class="icons">
                                                        <Flags :flag="record.color" /></a>
                                                </a-tooltip>
                                            </template>
                                        </a-table> -->
                                    </a-collapse-panel>
                                    <a-collapse-panel key="5" header="Care Plans">
                                        <a-table rowKey="id" :columns="carePlanColumns" :data-source="escalation.escalationCarePlan" :pagination="false">
                                        </a-table>
                                    </a-collapse-panel>
                                    <a-collapse-panel key="6" header="Flags">
                                        <a-table rowKey="id" :columns="flagColumns" :data-source="escalation.escalationFlags" :pagination="false">
                                            <template #name="{ record }">
                                                <span>{{record.flagName}}</span>
                                            </template>
                                            <template #color="{ record }">
                                                <a-tooltip placement="bottom">
                                                    <template #title>
                                                        <span>{{ record.flagName }}</span>
                                                    </template>
                                                    <a class="icons">
                                                        <Flags :flag="record.color" /></a>
                                                </a-tooltip>
                                            </template>
                                        </a-table>
                                    </a-collapse-panel>
                                </a-collapse>
                            </a-col>
                            <a-col :span="24" style="padding-top:20px">
                                <div class="formHeading">
                                    <h3>Assignee Details</h3>
                                    <hr />
                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <div class="form-group">
                                    <b>Name : </b> <span>{{'Park, Alan'}}</span>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <div class="form-group">
                                    <b>DoB : </b> <span>{{'July 05,1995'}}</span>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24">
                                <div class="form-group">
                                    <b>Designation : </b> <span>{{'Care Team'}}</span>
                                </div>
                            </a-col>

                            <a-col :span="24">
                                <div class="formHeading">
                                    <h3>Action Required</h3>
                                    <hr />
                                </div>
                            </a-col>
                            <a-col :sm="24" :xm="24">
                                <div class="form-group">
                                    <a-checkbox v-model:checked="escalationAction.appointment">Appointment</a-checkbox>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xm="24" v-if="escalationAction.appointment">
                                <div class="form-group">
                                    <a-form-item label="Note" name="description" :rules="[{ required: true, message: $t('tasks.tasksModal.longDescription')+' '+$t('global.validation')  }]">
                                        <a-textarea v-model:value="escalationAction.note1" placeholder="Description" :auto-size="{ minRows: 3 }" @change="checkChangeInput()" />
                                    </a-form-item>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xm="24">
                                <div class="form-group">
                                    <a-checkbox v-model:checked="escalationAction.carePlan">Care Plan</a-checkbox>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xm="24" v-if="escalationAction.carePlan">
                                <div class="form-group">
                                    <a-form-item label="Note" name="description" :rules="[{ required: true, message: $t('tasks.tasksModal.longDescription')+' '+$t('global.validation')  }]">
                                        <a-textarea v-model:value="escalationAction.note2" placeholder="Description" :auto-size="{ minRows: 3 }" @change="checkChangeInput()" />
                                    </a-form-item>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xm="24">
                                <div class="form-group">
                                    <a-checkbox v-model:checked="escalationAction.medication">Medication</a-checkbox>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xm="24" v-if="escalationAction.medication">
                                <div class="form-group">
                                    <a-form-item label="Note" name="description" :rules="[{ required: true, message: $t('tasks.tasksModal.longDescription')+' '+$t('global.validation')  }]">
                                        <a-textarea v-model:value="escalationAction.note3" placeholder="Description" :auto-size="{ minRows: 3 }" @change="checkChangeInput()" />
                                    </a-form-item>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xm="24">
                                <div class="form-group">
                                    <a-checkbox v-model:checked="escalationAction.diet">Diet</a-checkbox>
                                </div>
                            </a-col>
                            <a-col :sm="24" :xm="24" v-if="escalationAction.diet">
                                <div class="form-group">
                                    <a-form-item label="Note" name="description" :rules="[{ required: true, message: $t('tasks.tasksModal.longDescription')+' '+$t('global.validation')  }]">
                                        <a-textarea v-model:value="escalationAction.note4" placeholder="Description" :auto-size="{ minRows: 3 }" @change="checkChangeInput()" />
                                    </a-form-item>
                                </div>
                            </a-col>

                            <a-col :sm="12" :xs="24" style="padding-top: 30px">
                                <a-form-item :wrapper-col="{ span: 24, offset: 20 }">
                                    <a-button @click="cancel">Cancel</a-button>
                                    <a-button type="primary" html-type="submit" style="margin-left: 10px">{{$t('global.submit')}}</a-button>
                                </a-form-item>
                            </a-col>
                        </a-form>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</div>
</template>

<script>
import {
    defineComponent,
    reactive,
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import router from "../../router";
const notesColumns = [{
        title: "Date",
        dataIndex: "date",
        key: "date",
        className: "note-date",
    },
    {
        title: "Category",
        dataIndex: "category",
        key: "category",
        className: "note-category",
    },
    {
        title: "Type",
        dataIndex: "type",
        key: "type",
        className: "note-type",
    },
    {
        title: "Note",
        dataIndex: "note",
        key: "note",
        className: "note-text",
        ellipsis: true,
    },
    {
        title: "Added By",
        dataIndex: "addedBy",
        key: "addedBy",
        slots: {
            customRender: "addedBy",
        },
    },
    {
        title: "Priority",
        dataIndex: "color",
        slots: {
            customRender: "color",
        },
    },
];
const escalationNots = [{
        addedBy: "Admin, Super",
        addedById: "QghuN785By",
        category: "General",
        color: "#269A8E",
        date: "Jun 08, 2022",
        escalationId: 21,
        escalationType: "Notes",
        escalationTypeId: 260,
        flag: "High",
        id: "49f044ca-5e62-4aa8-a5c9-88f7cd752b3a",
        isActive: true,
        note: "test",
        notesId: 11579,
        type: "Timelogs",
        udid: "aae34b33-7705-4c61-901b-41f6cc73b0d5",

    },
    {
        addedBy: "Admin, Super",
        addedById: "QghuN785By",
        category: "General",
        color: "#269A8E",
        date: "Jun 08, 2022",
        escalationId: 21,
        escalationType: "Notes",
        escalationTypeId: 260,
        flag: "High",
        id: "49f044ca-5e62-4aa8-a5c9-88f7cd752b3a",
        isActive: true,
        note: "test",
        notesId: 11579,
        type: "Timelogs",
        udid: "aae34b33-7705-4c61-901b-41f6cc73b0d5"

    }
]

const escalationVitals = [{
        color: "#00B755",
        deviceType: "Oximeter",
        escalationId: 21,
        escalationType: "Vitals",
        escalationTypeId: 259,
        flagName: "Normal",
        id: "7c33d4a2-b7ea-4270-be16-6a545aecc475",
        isActive: true,
        patientVital: Object,
        takeTime: "May 20, 2022",
        udid: "2a9fdfd5-e78b-497a-9835-2434f7ad2e52",
        value: "95",
        vitalField: "SPO2",
        vitalId: 2776,

    },
    {
        color: "#F13535",
        deviceType: "Oximeter",
        escalationId: 21,
        escalationType: "Vitals",
        escalationTypeId: 259,
        flagName: "High",
        id: "9959da4c-4bc8-4f95-bd47-b331a3dcabf5",
        isActive: true,
        patientVital: Object,
        takeTime: "May 20, 2022",
        udid: "ce9f9a3b-0126-4b30-bafe-94131d134337",
        value: "104",
        vitalField: "BPM",
        vitalId: 2777,

    }
]

const escalationCarePlan = [{
    carePlanId: 66,
    deviceType: "Blood Pressure",
    endDate: "May 31, 2022",
    escalationId: 21,
    escalationType: "Care Plan",
    escalationTypeId: 262,
    frequency: "Daily",
    highValue: "100",
    id: "f200b53c-385d-408e-9019-cb4a920ca081",
    isActive: true,
    lowValue: "80",
    note: "Time Log",
    startDate: "May 19, 2022",
    udid: "431c48d6-e957-4ecc-b28c-26796b1c9fe5",
    vitalField: "Systolic",

}]

const escalationFlags = [{
    color: "#E73149",
    createdAt: "May 19, 2022",
    escalationId: 21,
    escalationType: "Flags",
    escalationTypeId: 261,
    flagColor: "#E73149",
    flagId: 113,
    flagName: "Critical",
    id: "5a9c149a-643b-44db-b728-1b600a44fdc6",
    isActive: true,
    name: undefined,
    udid: "dd16b920-1d43-4a30-828d-312892eee4f0"

}]

const vitalColumns = [{
        title: "Vital",
        dataIndex: "vitalField",
        key: "vitalField",
        className: "vital-vitalField",
    },
    {
        title: "Device Type",
        dataIndex: "deviceType",
        key: "deviceType",
        className: "vital-deviceType",
    },
    {
        title: "Value",
        dataIndex: "value",
        key: "value",
        className: "vital-value",
    },
    {
        title: "Date",
        dataIndex: "takeTime",
    },
    {
        title: "Color",
        dataIndex: "color",
        key: "color",
        className: "vital-color",
        slots: {
            customRender: "color",
        },
    },
];

const carePlanColumns = [{
        title: "Device",
        dataIndex: "deviceType",
    },
    {
        title: "Vital type",
        dataIndex: "vitalField",
    },
    {
        title: "Start date",
        dataIndex: "startDate",
    },
    {
        title: "End date",
        dataIndex: "endDate",
    },
    {
        title: "Frequency",
        dataIndex: "frequency",
        slots: {
            customRender: "frequency",
        },
    },
    {
        title: "High Value",
        dataIndex: "highValue",
    },
    {
        title: "Low Value",
        dataIndex: "lowValue",
    },
    {
        title: "Note",
        dataIndex: "note",
    },
];
const flagColumns = [{
        title: "Name",
        dataIndex: "name",
        slots: {
            customRender: "name",
        },
    },
    {
        title: "Date",
        dataIndex: "createdAt",
    },
    {
        title: "Color",
        dataIndex: "color",
        slots: {
            customRender: "color",
        },
    },
];
import Flags from "@/components/common/flags/Flags";
import PatientVitalsGrid from "@/components/patients/patientSummary/views/PatientVitalsGrid"
export default defineComponent({
    components: {
        PatientVitalsGrid,
        Flags
    },
    setup() {
        const store = useStore();
        const formRef = ref();
        const activeKey = ref([]);
        const escalationAction = reactive({
            note1: "",
            note2: "",
            note3: "",
            note4: "",
        });

        onMounted(() => {
          store.dispatch('patientDetails', 'e5ba1714-6276-4f32-9167-df5c028c813b')
          store.dispatch("singleEscalationRecord",'31b97dac-64fe-44d7-82e4-94ae746ca7db');
          store.dispatch("patientVitals", { patientId: 'e5ba1714-6276-4f32-9167-df5c028c813b', deviceType: 99, filter: '' })
          store.dispatch("patientVitals", { patientId: 'e5ba1714-6276-4f32-9167-df5c028c813b', deviceType: 100, filter: '' });
          store.dispatch("patientVitals", { patientId: 'e5ba1714-6276-4f32-9167-df5c028c813b', deviceType: 101, filter: '' });
          store.dispatch('devices', 'e5ba1714-6276-4f32-9167-df5c028c813b')
        });

        const escalation = computed(() => {
            return store.state.escalations;
        });
        const record = computed(() => {
            return store.state.escalations.singleEscalationRecord;
        });

        // const form = reactive({ ...escalationAction })

        const submitForm = () => {
            //
        };

        const cancel = () => {
            router.push('/')
        }

        return {
            escalationFlags,
            escalationCarePlan,
            escalationVitals,
            escalationNots,
            cancel,
            record,
            escalation,
            flagColumns,
            carePlanColumns,
            vitalColumns,
            notesColumns,
            activeKey,
            formRef,
            escalationAction,
            submitForm,
        };
    },
});
</script>

<style scoped>
.loginWrapper .logIn {
    max-width: 70% !important;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    height: 100vh;
    justify-content: center;
    padding: 15px 30px;
    overflow: inherit !important;
}

.logo {
    height: 100px;
    width: 100px;
}
</style>
